import React from 'react'
import Link from 'next/link'
import { useToggle } from 'react-use'
import { style } from './Navigation.style'

const Navigation: React.FC = () => {
  const [on, toggle] = useToggle(false)

  return (
    <div>
      <button css={on ? [style.open, style.menu] : style.menu} onClick={toggle}>
        <span css={style.bar}></span>
        <span css={style.bar}></span>
        <span css={style.bar}></span>
      </button>

      <nav css={on ? [style.nav, style.smallContent] : style.nav} id="nav">
        <ul css={on ? [style.navList, style.openNavList] : style.navList}>
          <li css={style.navItem('toyama')}>
            <Link href="/toyama">
              <a>
                <span css={style.navLink}>富山大学サイト</span>
              </a>
            </Link>
          </li>

          <li css={style.navItem('niigata')}>
            <Link href="/niigata">
              <a>
                <span css={style.navLink}>新潟大学サイト</span>
              </a>
            </Link>
          </li>
        </ul>
      </nav>
    </div>
  )
}

export default Navigation
