import { css } from 'styled-components'
import { minMq } from './breakPoints'
import variables from './variables'

const myLink = css`
  display: block;
  font-size: 1.5rem;
  font-weight: 500;
  min-height: 32px;
  position: relative;

  ${minMq('MD')} {
    font-size: 1.8rem;
    min-height: 40px;
  }

  &::after {
    border-bottom: 1px solid ${variables.BASE_TXC};
    border-right: 1px solid ${variables.BASE_TXC};
    bottom: 0;
    content: "";
    height: 10px;
    left: -4px;
    position: absolute;
    transform: skew(45deg);
    width: 100%;
  }

  &.is-hover::after {
    animation: moveArrow 1.2s ease infinite;
  }

  @keyframes moveArrow{
    0% {
      width: 94%;
    }

    100% {
      width: 100%;
    }
  }
`

export {
  myLink
}