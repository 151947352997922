import React from 'react'
import { NextPage } from 'next'
import { useRouter } from 'next/router'
import { createGlobalStyle } from 'styled-components'
import HeadInfo from '../HeadInfo'
import SiteHeader from '../SiteHeader/SiteHeader'
import SiteFooter from '../SiteFooter/SiteFooter'
import ScrollTop from '../ScrollTop/ScrollTop'
import Body from '../Body'
import normalize from '../../shared/styles/normalize'
import common from '../../shared/styles/common'
import icomoon from '../../shared/styles/icomoon'
import { style } from './Page.style'

const GlobalStyle = createGlobalStyle`
  ${normalize} 
  ${common}
  ${icomoon}
`

interface OwnProps {
  title: string
  children: React.ReactNode
}

type Props = OwnProps

const Page: NextPage<Props> = (props) => {
  const { asPath } = useRouter()
  const origin =
    typeof window !== 'undefined' && window.location.origin
      ? window.location.origin
      : ''

  return (
    <div css={style.page}>
      <GlobalStyle />
      <HeadInfo
        siteTitle={props.title}
        pageBaseUrl={origin}
        pagePath={asPath}
      />
      <SiteHeader />
      <Body>
        {props.children}
        <ScrollTop />
      </Body>
      <SiteFooter />
    </div>
  )
}

export default Page
