import { css } from 'styled-components'
import { minMq } from '../../shared/styles/breakPoints'
import variables from '../../shared/styles/variables'

const wrapScrollTopBtn = css`
  bottom: 0;
  position: sticky;
`

const scrollTopBtn = css`
  bottom: 0;
  font-weight: 700;
  height: 40px;
  line-height: 40px;
  position: absolute;
  right: 10px;
  transition: all .6s;
  width: 120px;
  z-index: 99;

  ${minMq('MD')} {
    height: 50px;
    line-height: 50px;
		right: 20px;
    width: 160px;
  }
  
  &.is-hover > span {
    opacity: .9;
  }

  > span {
    background-color: ${variables.THEME_C};
    border-radius: 15px 15px 0 0;
    display: block;
    font-size: 1.3rem;
    height: 100%;
    line-height: inherit;
    position: relative;
    text-align: center;
    transition: all .6s;
    
    ${minMq('MD')} {
      border-radius: 20px 20px 0 0;
      font-size: 1.5rem;
      font-weight: 700;
    }
  }
`

const activeStyle = css`
  opacity: 1;
`
const hiddenStyle = css`
  opacity: 0;
  pointer-events: none;
`

export const style = {
  wrapScrollTopBtn,
  scrollTopBtn,
  activeStyle,
  hiddenStyle
}