import React from 'react'
import Link from 'next/link'
import getConfig from 'next/config'
import { style } from './SiteHeader.style'
import Navigation from '../Navigation/Navigation'

const { publicRuntimeConfig } = getConfig()
const basePath = publicRuntimeConfig.basePath

const SiteHeader: React.FC = () => {
  return (
    <header css={style.siteHeader} id="site_header">
      <div css={style.siteTitle}>
        <Link href="/">
          <a>
            <img
              src={`${basePath}/common/logo.png`}
              alt="北越地域医療人養成センターのロゴ"
            />
          </a>
        </Link>
      </div>
      <div css={style.wrapNav}>
        <a
          href="https://twitter.com/hokuetsu_dc"
          target="_blank"
          rel="noopener noreferrer"
          css={style.twitterBtn}
        >
          <img
            src={`${basePath}/common/twitter_icon.png`}
            alt="ツイッターのロゴ"
          />
        </a>
        <Navigation />
      </div>
    </header>
  )
}

export default SiteHeader
