import { css } from 'styled-components'
import { maxMq, minMq } from '../../shared/styles/breakPoints'
import variables from '../../shared/styles/variables'

const smallContent = css`
  /* height: 100vh; */
  opacity: 1;
  pointer-events: all;
  
  &::before {
    opacity: 1;
    transform: translate(0);
  }
`

const nav = css`
  align-items: flex-end;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  height: 0;
  left: 0;
  opacity: 0;
  padding-top: calc(${variables.HEADER_H_SP} * 2);
  pointer-events: none;
  position: fixed;
  top: 0;
  transition: opacity .6s ease,height .6s ease;
  width: 100vw;
  
  ${minMq('MD')} {
    background-color: ${variables.WHITE};
    display: block;
    height: 100%;
    opacity: 1;
    padding-top: 0;
    pointer-events: visible;
    position: static;
    transition: none;
    width: 100%;
  }
  
  &::before {
    background: linear-gradient(240deg,#ffffff,#ffffff 55%,rgba(255,255,255,0) 65%);
    box-shadow: 0 2px 4px #0000001a;
    content: '';
    display: block;
    left: auto;
    min-height: 200%;
    position: absolute;
    right: 0;
    top: 0;
    transform: translate(300%);
    width: 200%;
    z-index: -1;

    ${minMq('MD')} {
      content: none;
    }

    ${maxMq("XXS")} {
      min-height: 255%;
    }
  }
`

const openNavList = css`
  opacity: 1;
  pointer-events: all;
  
`

const navList = css`
  box-sizing: border-box;
  display: flex;
  flex-wrap: wrap;
  font-weight: 500;
  gap: 20px;
  height: auto;
  justify-content: center;
  margin: 0 auto;
  opacity: 0;
  transition: opacity .3s ease,height .3s ease;

  ${minMq('MD')} {
    gap: 12px;
    height: 100%;
    justify-content: flex-end;
    opacity: 1;
    width: 100%;
  }

  ${maxMq("XXS")} {
    flex-direction: column;
  }
`

const navItem = (category: "toyama" | "niigata") => {
  const color = category === "toyama" ? variables.LIGHT_BLUE : variables.SEAGREEN

  return css`
    a {
      align-items: center;
      background-color: ${color};
      border-radius: 10px;
      color: ${variables.WHITE};
      display: flex;
      padding: 11px 23px;

      &.is-hover {
        opacity: .7;
        transition: opacity .6s;

        span {
          opacity: 1;
        }
      }
    }
  `
}

const navLink = css`
  font-size: 1.6rem;
  font-weight: 500;
`

const menu = css`
  cursor: pointer;
  height: 24px;
  position: relative;
  width: 30px;
  z-index: 11;

  ${minMq('MD')} {
    display: none;
  }
`

const bar = css`
  background-color: ${variables.BASE_TXC};
  border-radius: 1px;
  display: block;
  height: 2px;
  max-height: 2px;
  min-height: 2px;
  position: absolute;
  right: 0;
  transition: 0.6s ease;
  width: 30px;
  
  &:nth-child(1){
    top:0;
  }
  &:nth-child(2){
    top: 11px;
  }
  
  &:nth-child(3){
    bottom: 0;
  }
`

const open = css`
  span{
    &:nth-child(1){
      background-color: ${variables.BASE_TXC};
      right: -3px;
      top: 11px;
      transform: rotate(-45deg);
    }
    
    &:nth-child(2){
      opacity: 0;
      width: 20px;
    }
    
    &:nth-child(3){
      background-color: ${variables.BASE_TXC};
      bottom: 11px;
      right: -3px;
      transform: rotate(45deg);
    }
  }
`

export const style = {
  smallContent,
  nav,
  openNavList,
  navList,
  navItem,
  navLink,
  menu,
  bar,
  open
}