import { css } from 'styled-components'
import { minMq } from '../../shared/styles/breakPoints'
import variables from '../../shared/styles/variables'


const siteHeader = css`
  align-items: center;
  background-color: ${variables.WHITE};
  box-shadow: 0 2px 4px #0000001a;
  display: flex;
  height: ${variables.HEADER_H_SP};
  justify-content: space-between;
  left: 0;
  padding: calc( ${variables.UNIT} * 2 );
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 99;

  ${minMq('MD')} {
    height: ${variables.HEADER_H_PC};
    justify-content: space-between;
    padding: calc( ${variables.UNIT} * 2 ) 6%;
  }
`

const siteTitle = css`
  align-items: center;
  display: flex;
  position: static;
  width: min(60vw, 240px);
  z-index: 1;

  ${minMq('MD')} {
    width: min(35vw, 408px);
  }

  > a {
    &.is-hover > img {
      opacity: 1;
    }
  }
`

const wrapNav = css`
  display: flex;
`

const twitterBtn = css`
  align-items: center;
  display: flex;
  margin: 0 13px;
  z-index: 1;

  ${minMq('MD')} {
    margin-left: 8px;
    margin-right: 30px;
  }

  > img {
    width: 32px;
  }
`

export const style = {
  siteHeader,
  siteTitle,
  wrapNav,
  twitterBtn
}