export default {
  WHITE: '#fff',
  GRAY: '#8C8C8C',
  LIGHT_GRAY: '#E3E3E3',
  BLACK: '#000',
  RED: '#e60012',
  GREEN: '#488b4b',
  DARK_GREEN: '#007F4D',
  SEAGREEN: "#0BC59D",
  LIGHT_SEAGREEN: "#A4EBDB",
  BLUE: '#0f67c3',
  DARK_BLUE: '#42627B',
  LIGHT_BLUE: "#6B9EC7",
  SKY_BLUE: "#9FC5E3",
  BLUE_GREEN: "#3BB2B2",
  ORANGE: "#F2A71B",
  LIGHT_YELLOW: "#FFF3B9",
  PINK: '#e3556a',
  NAVY: "#203540",
  BASE_BGC: '#F2F2F2',
  BASE_BDC: '#4d311c',
  SUB_BDC: '#c4c4c4',
  BASE_TXC: '#203540',

  // テーマカラー
  THEME_C: '#F2F2F2',
  SUB_C: '#19A7FF',

  // エラー表示用の強調色
  ERR_C: '#f20606',
  //$err_bgc: tint($err_c, 95%);

  // リンクの色
  LINK_TXC: '#1a73e8',
  LINK_HVC: '#18e2e2',

  // ボタンの色
  BTN_BGC: '#fef6e5',
  //$btn_hvc: lighten($theme_c, 15%);
  BTN_TXC: '#4d311c',

  // カレンダーUIの色
  CAL_BGC: '#fff',
  CAL_TXC: '#333',
  CAL_BDC: '#aeaeae',
  CAL_HVC: '#ededed',
  CAL_SAT_TXC: '#0f67c3',
  CAL_SUN_TXC: '#e60012',

  // size
  UNIT: '5px',
  BLOCK_SPACE: `${5 * 6}px`,
  BLOCK_PAD: `${5 * 4}px`,

  CONTENT_W: '1020px',
  HEADER_H_PC: '90px',
  HEADER_H_SP: '60px',
  GLOBAL_NAV_W: '44px',
  LOGO_H_SP: '30px',
  BTN_CONTACT_H_SP: '40px'
}
