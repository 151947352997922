import { css } from 'styled-components'
import { maxMq, minMq } from '../../shared/styles/breakPoints'
import variables from '../../shared/styles/variables'

const siteFooter = css`
  background-color: ${variables.BASE_BGC};
  padding: 60px 0 20px;
  width: 100%;
`

const footerLogo = css`
  display: block;
  margin-bottom: 40px;
  max-width: 240px;
  
  ${minMq('MD')} {
    margin: 0 auto 62px;
    max-width: 408px
  }

  a.is-hover > img {
    opacity: 1;
  }
`

const infoBlock = css`
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  margin-bottom: 60px;
  row-gap: 32px;

  ${minMq('MD')} {
    grid-template-columns: repeat(auto-fit, 500px);
    justify-content: center;
    margin-bottom: 40px;
    row-gap: 20px;
  }
`

const info = css`
  ${minMq('MD')} {
    display: grid;
    height: 150px;
    place-content: center;
  }

  
  > div {
    ${maxMq('LG')} {
      width: 380px;
    }

    ${maxMq('MD')} {
      width: auto;
    }
  }
`

const name = css`
  font-weight: 700;
  margin-bottom: 14px;
`

const copyright = css`
  font-size: 1.4rem;
  text-align: center;
`


export const style = {
  siteFooter,
  footerLogo,
  infoBlock,
  info,
  name,
  copyright
}