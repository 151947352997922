import variables from '../shared/styles/variables'

export type Category = 'toyama' | 'niigata' | 'common'

export type Subcategory = 'news' | 'event' | 'report'

export type Tag = 'all' | Category

export type TagJp = '全て' | '富山' | '新潟' | '共通'

type TagItems = {
  value: Tag
  text: TagJp
}[]

export type Query = {
  tag?: Tag | undefined
  page?: string | undefined // ページネーションの選択中のページナンバー
  subcategory?: Subcategory | undefined
  university?: 'toyama' | 'niigata' | undefined
}

export const tagItems: TagItems = [
  {
    value: 'all',
    text: '全て',
  },
  {
    value: 'toyama',
    text: '富山',
  },
  {
    value: 'niigata',
    text: '新潟',
  },
  {
    value: 'common',
    text: '共通',
  },
]

export const getColor = (tag: Tag) => {
  let color = ""
  switch (tag) {
    case "all":
      color = variables.BASE_TXC
      break

    case "toyama":
      color = variables.LIGHT_BLUE
      break

    case "niigata":
      color = variables.SEAGREEN
      break

    case "common":
      color = variables.ORANGE
      break
  }

  return color
}

export const getCategoryJp = (category: Tag) => {
  return tagItems.find(item => item.value === category)?.text
}

export const getSubcategoryJp = (subcategory: Subcategory) => {
  let ret = "" as "新着情報" | "イベント・研修会" | "活動報告"

  switch (subcategory) {
    case 'news':
      ret = "新着情報"
      break;

    case 'event':
      ret = "イベント・研修会"
      break;

    case 'report':
      ret = "活動報告"
      break;
  }

  return ret
}