import React from 'react'
import Link from 'next/link'
import { style } from './SiteFooter.style'
import Content from '../Content/Content'
import getConfig from 'next/config'

const { publicRuntimeConfig } = getConfig()
const basePath = publicRuntimeConfig.basePath

const SiteFooter: React.FC = () => {
  return (
    <footer css={style.siteFooter}>
      <Content>
        <Link href="/">
          <a css={style.footerLogo}>
            <img src={`${basePath}/common/logo.png`} alt="プレスメディア" />
          </a>
        </Link>
        <div css={style.infoBlock}>
          <div css={style.info}>
            <div>
              <p css={style.name}>北越地域医療人養成センター</p>
              <p>〒930-0194</p>
              <p>富山市杉谷2630番地&emsp;富山大学医学部&emsp;内</p>
              <p>E-mail：hokuetsu@med.u-toyama.ac.jp</p>
            </div>
          </div>
          <div css={style.info}>
            <div>
              <p css={style.name}>北越地域医療人養成センター 新潟分室</p>
              <p>〒951-8510</p>
              <p>新潟市中央区旭町通1番町757&emsp;新潟大学医学部&emsp;内</p>
              <p>E-mail：hokuetsu@med.niigata-u.ac.jp</p>
            </div>
          </div>
        </div>
        <div css={style.copyright}>
          <p>© 2023 北越地域医療人養成センター</p>
        </div>
      </Content>
    </footer>
  )
}

export default SiteFooter
