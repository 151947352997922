import React from 'react'
import { css } from 'styled-components'
import { minMq } from '../../shared/styles/breakPoints'
import variables from '../../shared/styles/variables'

const content = css`
  margin-left: auto;
  margin-right: auto;
  max-width: 100%;
  padding-left: 18px;
  padding-right: 18px;
  word-wrap: break-word;

  ${minMq('MD')} {
    padding-left: calc(${variables.UNIT} * 2);
    padding-right: calc(${variables.UNIT} * 2);
    width: ${variables.CONTENT_W};
  }
`

interface OwnProps {
  children: React.ReactNode
  className?: string
}

type Props = OwnProps

const Content: React.FC<Props> = (props) => {
  return (
    <div className={props.className || ''} css={content}>
      {props.children}
    </div>
  )
}

export default Content
