import { css } from 'styled-components'
import { minMq } from './breakPoints'
import variables from './variables'

const normalize = css`
  html {
    font-size: 62.5%;
    line-height: 1.5;
    -webkit-text-size-adjust: 100%;
  }

  body {
    color: ${variables.BASE_TXC};
    font-weight: 400;

    ${minMq('MD')} {
      font-weight: 500;
    }
  }

  *:where(:not(iframe, canvas, img, svg, video, sub, sup):not(svg *):not(.default-style *)) {
    all: unset;
    display: revert;
  }

  /* Preferred box-sizing value */
  *,
  *::before,
  *::after {
    box-sizing: border-box;
  }

  /* Remove list styles (bullets/numbers) */
  ol,
  ul {
    list-style: none;
  }

  /* For images to not be able to exceed their container */
  img {
    max-width: 100%;
    height: auto;
  }

  /* removes spacing between cells in tables */
  table {
    border-collapse: collapse;
  }
`

export default normalize
